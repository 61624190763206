import fromPairs from 'lodash/fromPairs';

export type JobResumeRecruitersOptionsType = { label: string; value: string };

export const statusOptions: JobResumeRecruitersOptionsType[] = [
  // { label: 'None', value: 'none' },
  { label: 'Actively looking', value: 'actively_looking' },
  { label: 'Actively interviewing', value: 'actively_interviewing' },
  { label: 'Passively curious', value: 'passively_interviewing' },
  { label: 'Off market', value: 'off_market' },
  {
    label: 'Selectively exploring options',
    value: 'not_looking'
  }
];

export const paidTimeOffOptions: JobResumeRecruitersOptionsType[] = [
  // { label: 'None', value: 'none' },
  { label: '1 week', value: '1_week' },
  { label: '2 weeks', value: '2_week' },
  { label: '3 weeks', value: '3_week' },
  { label: '4 weeks', value: '4_week' },
  { label: '5 weeks', value: '5_week' },
  { label: '6 weeks', value: '6_week' },
  { label: '7 weeks', value: '7_week' },
  { label: '8 weeks', value: '8_week' },
  { label: 'Unlimited', value: 'unlimited' }
];

export const startAvailabilityOptions: JobResumeRecruitersOptionsType[] = [
  {
    label: 'Immediately after offer acceptance',
    value: 'immediately'
  },
  {
    label: '1 week after offer acceptance',
    value: 'one_week'
  },
  {
    label: '2 weeks after offer acceptance',
    value: 'two_weeks'
  },
  {
    label: '3 weeks after offer acceptance',
    value: 'three_weeks'
  },
  {
    label: '4 weeks after offer acceptance',
    value: 'four_weeks'
  },
  {
    label: '5 + weeks after offer acceptance',
    value: 'five_weeks'
  }
];

export const paidTimeOffOptionsHash = fromPairs(
  paidTimeOffOptions.map(({ value, label }) => [value, label])
);

export const employmentOptions: JobResumeRecruitersOptionsType[] = [
  // { label: 'None', value: 'none' },
  { label: 'Full time', value: 'full_time' },
  { label: 'Part time', value: 'part_time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Intern', value: 'intern' }
];

export const employmentTypesOptions: JobResumeRecruitersOptionsType[] = [
  // { label: 'None', value: 'none' },
  { label: 'Full time', value: 'full_time' },
  { label: 'Part time', value: 'part_time' },
  { label: 'Contract', value: 'contract' },
  { label: 'Intern', value: 'intern' }
];

export const workplaceTypesOptions: JobResumeRecruitersOptionsType[] = [
  // { label: 'None', value: 'none' },
  { label: 'Onsite', value: 'onsite' },
  { label: 'Hybrid', value: 'hybrid' },
  { label: 'Remote', value: 'remote' }
];

export const workplaceTypesOptionsHash = fromPairs(
  workplaceTypesOptions.map(({ value, label }) => [value, label])
);

export const minimumDesiredPayOptions: JobResumeRecruitersOptionsType[] = [
  { label: '$25k - $35k', value: '25k_35k' },
  { label: '$35k - $45k', value: '35k_45k' },
  { label: '$45k - $55k', value: '45k_55k' },
  { label: '$55k - $65k', value: '55k_65k' },
  { label: '$65k - $75k', value: '65k_75k' },
  { label: '$75k - $85k', value: '75k_85k' },
  { label: '$85k - $95k', value: '85k_95k' },
  { label: '$95k - $100k', value: '95k_100k' },
  { label: '$100k - $125k', value: '100k_125k' },
  { label: '$125k - $150k', value: '125k_150k' },
  { label: '$150k - $175k', value: '150k_175k' },
  { label: '$175k - $200k', value: '175k_200k' },
  { label: '$200k - $225k', value: '200k_225k' },
  { label: '$225k - $250K', value: '225k_250k' },
  { label: '$250k - $300k', value: '250k_300k' },
  { label: '$300k<', value: '300k' }
];

export const futureRequirementsOptions: JobResumeRecruitersOptionsType[] = [
  { label: 'Visa Sponsorship', value: 'visa_sponsorship' },
  { label: 'Visa Transfer', value: 'visa_transfer' },
  { label: 'Green Card Processing', value: 'green_card_processing' }
];
