import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { Popover, PopoverAnchor } from '@chakra-ui/popover';
import { Portal } from '@chakra-ui/react';

import {
  MayBeSkillBadgeEmploymentSkillBadges,
  MayBeSkillBadgeSkillLicense,
  SkillBadgeCertified,
  SkillBadgeExperience,
  SkillBadgeExperienceType,
  SkillBadgeRatingType,
  SkillBadgeRequirements,
  SkillBadgeSkill
} from '../../skillBadgesTypes';

import { CertifiedIcon } from '../../../../icons/CertifiedIcon';

import { Text } from '../../../../helpers/Text';

import { SkillBadgeOnlyPopoverContent } from '../../components/SkillBadgeOnlyPopoverContent';
import { renderSkillBadgeExperience } from '../../utils/renderSkillBadgeExperience';
import { renderSkillBadgeRatingBackground } from '../../utils/renderSkillBadgeRatingBackground';
import { renderMostRecentJob } from '../../../jobs/utils/renderMostRecentJob';

interface SkillBadgeProps {
  name: SkillBadgeSkill['name'] | string;
  rating: SkillBadgeRatingType;
  experience: SkillBadgeExperience;
  certified: SkillBadgeCertified | boolean;
  requirements: SkillBadgeRequirements;
  skillLicense: MayBeSkillBadgeSkillLicense;
  withoutPortal?: boolean;
  withoutPopover?: boolean;
  employmentSkillBadges?: MayBeSkillBadgeEmploymentSkillBadges;
  experienceType?: SkillBadgeExperienceType;
  hideRequirements?: boolean;
}

function SkillBadgeOnly({
  name,
  experience,
  rating,
  certified,
  skillLicense,
  requirements,
  withoutPortal,
  withoutPopover,
  employmentSkillBadges,
  experienceType,
  hideRequirements
}: SkillBadgeProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const experienceLabel = renderSkillBadgeExperience(experience);
  const recentUsage = renderMostRecentJob(employmentSkillBadges)?.usage;

  return (
    <Popover
      returnFocusOnClose={false}
      onClose={onClose}
      isOpen={isOpen}
      placement="top-start"
      offset={[0, 8]}
      isLazy
    >
      <PopoverAnchor>
        <Flex
          as="button"
          bg={renderSkillBadgeRatingBackground(rating)?.main}
          borderRadius={4}
          alignItems="center"
          overflow="hidden"
          color="white"
          cursor={withoutPopover ? 'default' : 'pointer'}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <Flex px={2} alignItems="center">
            {certified && <CertifiedIcon mr={1} />}
            <Text
              textStyle="body1Medium"
              textAlign="left"
              isTruncated
              noOfLines={1}
            >
              {name}
            </Text>
          </Flex>
          {experienceLabel ? (
            <Flex
              bg={renderSkillBadgeRatingBackground(rating)?.experience}
              borderLeft="1px solid"
              borderLeftColor={
                renderSkillBadgeRatingBackground(rating)?.experience
              }
              px={2}
            >
              <Text
                textStyle="body1Medium"
                maxW="max-content"
                whiteSpace="nowrap"
              >
                {experienceLabel}
              </Text>
            </Flex>
          ) : null}
        </Flex>
      </PopoverAnchor>

      {withoutPopover ? null : (
        <>
          {withoutPortal ? (
            <SkillBadgeOnlyPopoverContent
              name={name}
              rating={rating}
              certified={certified}
              experience={experience}
              skillLicense={skillLicense}
              requirements={requirements}
              recentUsage={recentUsage}
              experienceType={experienceType}
              hideRequirements={hideRequirements}
            />
          ) : (
            <Portal>
              <SkillBadgeOnlyPopoverContent
                name={name}
                rating={rating}
                certified={certified}
                experience={experience}
                skillLicense={skillLicense}
                requirements={requirements}
                recentUsage={recentUsage}
                experienceType={experienceType}
                hideRequirements={hideRequirements}
              />
            </Portal>
          )}
        </>
      )}
    </Popover>
  );
}

export default SkillBadgeOnly;
